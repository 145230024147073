<script>
import { Mixin } from '@/mixins/util.js'
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Row,
  Col,
  RadioGroup,
  RadioButton
} from 'element-ui'
export default {
  name: 'set',
  mixins: [Mixin],
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-pagination': Pagination,
    'el-row': Row,
    'el-col': Col,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton
  },
  data() {
    return {
      allproductsSource: [],
      source: 'ALL',
      curPage: 1,
      table_1: [],
      table_24: [],
      table_25: [],
      colConfigs: [
        { prop: 'idx', label: '#', width: '60', fixed: true },
        { prop: '0', label: 'セット商品名', width: '300', fixed: true },
        { prop: '1', label: 'セット商品コード', width: '140', fixed: true },
        { prop: '2', label: '來源', width: '140', fixed: true },
        { prop: '5', label: '個数', fixed: false }
      ],
      pagesize: 10,
      total: 0
    }
  },
  computed: {
    filterData() {
      return this.TotableData.filter(data => {
        if (this.source === 'Launch cart')
          return data['2'] === this.source || data['2'] === 'LC'
        else if (this.source === 'ALL') return this.TotableData
        else return data['2'] === this.source
      })
    },
    tableData() {
      return this.TotableData.slice(
        (this.curPage - 1) * this.offset,
        (this.curPage - 1) * this.offset + this.offset
      )
    },
    TotableData() {
      if (!this.allproductsSource) return []
      return this.table_25
      .map((items) => {
        //add source

        const filterData = this.allproductsSource.filter(d => {
          return d[2] === items[1]
        })

        let temp = items.map((item, i) => {
          if (!isNaN(item * 1) && i > 1) return (item * 1).toLocaleString()
          return item
        })
        //.splice(2, 1, '')
        if (filterData.length > 0) {
          const productSource = filterData[0][16].source || 'Launch cart'
          temp.splice(2, 0, productSource)
        } else temp.splice(2, 0, 'Launch cart')
        let temp1 = { ...temp }
        // temp1['idx'] = index + 1
        return temp1
      })
      .sort((a,b)=>{
        if(a[1]>b[1]) return 1
        else return -1
      })
      .map((item,index) => {
        item['idx'] = index +1
        return item
      })
    },
    contentData() {
      const data = this.TotableData.map(item => {
        const data = Object.entries(item)
        data.pop()
        data.splice(3, 1)
        return data.map(t => t[1].replace(/,/g, ''))
      })
      return data
    },
    table_24Edit() {
      if (this.table_24.length == 0 && this.allproductsSource) return []
      if (this.allproductsSource === null) return []
      return this.table_24[0].map(item => {
        const label = this.allproductsSource.reduce((a, c) => {
          if (c[2] === item && item !== '') a = `${c[2]}\n${c[4]}`
          return a
        }, '')
        return label
      })
    },
    header() {
      const title = this.colConfigs.map(item => Object.entries(item)[1][1])
      title.shift()
      let tempH = []
      tempH.unshift('', '', '')
      return [title, [...tempH, ...this.table_24Edit]]
    },
    excelData() {
      return [[...this.header, ...this.contentData]]
    },
    mergeCells() {
      return [
        [
          { Scol: 4, Srow: 1, Ecol: 4 + this.table_24[0].length - 1, Erow: 1 },
          { Scol: 1, Srow: 1, Ecol: 1, Erow: 2 },
          { Scol: 2, Srow: 1, Ecol: 2, Erow: 2 },
          { Scol: 3, Srow: 1, Ecol: 3, Erow: 2 }
        ]
      ]
    },
    pageTotal() {
      return (this.filterData.length / this.pagesize) * 10
    }
  },
  methods: {
    dowexcel() {
      this.dwnExcel(
        this.excelData,
        'セット商品',
        ['セット商品マスター'],
        this.mergeCells
      )
    },
    getChartDataWithTimeOut(y, m) {
      const result = this.fetchWithTimeout(y, m, '1,24,25')
      this.$eventBus.$emit('loadingHandler', true)
      result
        .then(res => res.text())
        .then(rep => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          this.$eventBus.$emit('loadingHandler', false)
          // console.log(rep)
          if (rep.errno !== '0') {
            this.$eventBus.$emit('logOutHandler', true)
            this.ToLoginPage()
          } else {
            const keys = Object.keys(rep).filter(item => !isNaN(item))
            keys.forEach(key => (this[`table_${key}`] = rep[key]))
            this.table_24[0] = this.table_24[0].map(item =>
              this.Mosaic(item, sessionStorage.getItem('session_id'))
            )
            this.table_25.map(item => {
              item[0] = this.Mosaic(
                item[0],
                sessionStorage.getItem('session_id')
              )
              item[1] = this.Mosaic(
                item[1],
                sessionStorage.getItem('session_id')
              )
              return item
            })
          }
        })
        .catch(() => {
          this.$eventBus.$emit('loadingHandler', false)
          this.$eventBus.$emit('serverDownHandler', true)
          this.ToLoginPage()
        })
    },
    async getSupplierAllProducts() {
      const hostname = 'https://www.bamb.asia/app/hermers/allProductList'
      // const hostname =
      // 'http://192.168.1.231:8080/app/hermers/supplierAllProducts'
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: `sessionid=${s_id}&supplier_uid=${s_uid}`
      }
      if (s_uid == '2' || s_uid == '99') {
        for (const limit of [0, 500, 1000]) {
          config.body = `sessionid=${s_id}&supplier_uid=${s_uid}&limit_begin=${limit}&limit_count=500`
          await fetch(hostname, config)
            .then(async res => await res.text())
            .then(async rep => {
              const data = JSON.parse(this.RemoveNonValidJSONChars(rep))
              if (data.records.length != 0)
                this.allproductsSource = [
                  ...this.allproductsSource,
                  ...data.records
                ]
            })
        }
      } else {
        await fetch(hostname, config)
          .then(res => res.text())
          .then(rep => {
            rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
            this.allproductsSource = rep.records
          })
      }
    },
    ToLoginPage() {
      this.timer = setTimeout(() => {
        this.$router.push({
          name: 'login',
          params: { t: '123' }
        })
      }, 2500)
    },
    current_change(currentPage) {
      this.curPage = currentPage
    }
  },
  created() {
    // this.getData(new Date().getFullYear(), new Date().getMonth() + 1)
    this.getChartDataWithTimeOut(
      new Date().getFullYear(),
      new Date().getMonth() + 1
    )
    this.getSupplierAllProducts()
  },
  mounted() {
    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
